import React from "react"

// Components
import Layout from "../../../components/layout"
import Band from "../../../components/band"
import SEO from "../../../components/seo"
import FlowDataTableTopsNav from "../../../components/top-nav/flow-top-nav"

// CSS
import grid from "../../../shared/bootstrap-grid.module.css"

// Files
import ArchitecturalOverviewPdfSrc from "../../../files/Flow-Data-Table-Technical-Overview.pdf"

const FlowDataTableArchitecturalOverviewPage = () => (
  <Layout>
    <SEO title="Flow Data Table" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `flow data table`]} />

    <FlowDataTableTopsNav />

    <Band>
      <h1>Architectural Overview</h1>

      The architectural overview is available in PDF version to make distribution easier.
      <div className={grid.mt2}>
        <a href={ArchitecturalOverviewPdfSrc} target="_blank" rel="noopener noreferrer">View PDF</a>
      </div>
    </Band>
  </Layout>
);

export default FlowDataTableArchitecturalOverviewPage;